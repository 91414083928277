<template>
  <div class="chart-wrapper-area">
    <div :id="`chart-wrapper-area-${city}`">
      <svg :id="`area-${city}`" />
    </div>

    <div :class="[city, 'tooltip-area', 'text-body-xs', 'dirchange']">
      <p class="text-body-xs mb-0">{{ $t('tooltip.year') }}: {{ selectedItem.year }}</p>
      <span v-if="parameter.id.includes('prep')">{{ $t('tooltip.precip') }}: {{ selectedItem[parameter.id] }}</span>
      <span v-else-if="parameter.id.includes('days')">{{ $t('tooltip.days') }}: {{ selectedItem[parameter.id] }}</span>
      <div v-else>
        <p class="text-body-xs mb-0">{{ $t('tooltip.max') }}: {{ selectedItem[range.max] }}</p>
        <p class="text-body-xs mb-0 font-weight-medium">{{ $t('tooltip.ave') }}: {{ selectedItem[parameter.id] }}</p>
        <p class="text-body-xs mb-0">{{ $t('tooltip.min') }}: {{ selectedItem[range.min] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'VizAreaChart',
  props: {
    city: {
      type: String,
      required: true,
    },
    parameter: {
      type: Object,
      default: () => {},
      required: true,
    },
    range: {
      type: Object,
      default: () => {},
      required: false,
    },
    annotation: {
      type: Boolean,
      default: false,
      required: false,
    },
    trigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    chartDim() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          width: 1200,
          height: 360,
          marginY: 42,
          marginX: 72,
        };
      } else if (this.$vuetify.breakpoint.smOnly) {
        return {
          width: 760,
          height: 340,
          marginY: 36,
          marginX: 60,
        };
      } else {
        return {
          width: 580,
          height: 300,
          marginY: 30,
          marginX: 60,
        };
      }
    },
    locationLabel() {
      return this.$t('btn.location.' + this.city);
    },
    axisLabel() {
      return this.$t(this.parameter.axisLabel);
    }
  },
  data() {
    return {
      yearRange: [1950, 2021],
      data: null,
      dataChange: null,
      selectedItem: {
        year: '2018',
        annual_temp: '27.3',
        summer_temp: '36.6',
        max_temp: '42.7',
        min_temp: '29.4',
        annual_hi: '25.4',
        summer_hi: '33.2',
        max_hi: '38.0',
        dangerous_days: '12',
        days_100f: '142',
        annual_prep: '60.9',
        summer_prep: '0.0',
      },
    };
  },
  watch: {
    parameter(param) {
      const areachart = ['days', 'prep'];
      areachart.some((d) => param.id.includes(d))
        ? this.updateAreaChart(param)
        : this.updateMinMaxChart(param, this.range);
    },
    trigger(newVal) {
      if (!newVal) {
        const areachart = ['days', 'prep'];
        areachart.some((d) => this.parameter.id.includes(d))
          ? this.updateAreaChart(this.parameter)
          : this.updateMinMaxChart(this.parameter, this.range);
      }
    },
    '$i18n.locale': function() {
      const chart = d3.select(`#area-${this.city}`)
      // translate location label
      chart
        .selectAll('text.location-label')
        .text(this.locationLabel);

      // translate Y-axis label
      chart
        .selectAll('text.axis-label')
        .text(this.axisLabel);
    }    
  },
  async mounted() {
    const rawData = await d3.csv(`${process.env.BASE_URL}data/${this.city}.csv`);
    const rawDataChange = await d3.csv(`${process.env.BASE_URL}data/param-change.csv`);
    
    this.data = rawData.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));
    this.dataMap = d3.group(this.data, (d) => d.item);

    this.dataChange = rawDataChange.find(d => d.location === this.city);

    // clean the chart before starting
    d3.select(`#area-${this.city}`).selectAll('g').remove();

    this.xScale = this.initChart();
    this.yScale = this.initYAxis();

    const areachart = ['days', 'prep'];
    areachart.some((d) => this.parameter.id.includes(d))
      ? this.updateAreaChart(this.parameter)
      : this.updateMinMaxChart(this.parameter, this.range);
  },
  methods: {
    // drawing chart base functions
    initChart() {
      const innerWidth = this.chartDim.width - this.chartDim.marginX;
      const svg = d3
        .select(`#chart-wrapper-area-${this.city}`)
        .select(`#area-${this.city}`)
        .attr('width', this.chartDim.width)
        .attr('height', this.chartDim.height)
        // .style('border', '1px solid #2A2A2A')
        .call(this.responsivefy);

      this.chart = svg
        .append('g')
        .attr('class', 'chart-area')
        .attr('transform', `translate(${this.chartDim.marginX}, 0)`);

      // draw grid lines
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M 0,${this.chartDim.marginY} L ${innerWidth},${this.chartDim.marginY} V ${this.chartDim.marginY},${this.chartDim.height} H ${innerWidth},0 Z`
        );

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M ${this.chartDim.marginX / 1.75},${this.chartDim.marginY} V ${this.chartDim.marginX / 1.75},${
            this.chartDim.height
          }`
        );

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M 0,${this.chartDim.height - this.chartDim.marginY} L ${this.chartDim.marginX},${
            this.chartDim.height - this.chartDim.marginY
          } L  ${this.chartDim.marginX},${this.chartDim.height}`
        );

      svg
        .append('text')
        .attr('text-anchor', 'start')
        .attr('class', 'location-label')
        .attr('transform', `translate(0, 20)`)
        .text(this.locationLabel);

      svg
        .append('text')
        .attr('class', 'axis-label')
        .attr('opacity', 1)
        .attr('text-anchor', 'start')
        .attr(
          'transform',
          `translate(${this.chartDim.marginX / 3}, ${this.chartDim.height - this.chartDim.marginY - 2}) rotate(-90)`
        )
        .text(this.axisLabel);

      // draw the x-axis that doesn't change
      const x = d3
        .scaleLinear()
        .domain(this.yearRange)
        .range([0, this.chartDim.width - this.chartDim.marginX * 2]);

      this.chart
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${this.chartDim.height - this.chartDim.marginY})`)
        .call(
          d3
            .axisBottom(x)
            // .tickValues([1979, 1989, 1999, 2009, 2019])
            .tickFormat(d3.format('d'))
            .tickSize(-this.chartDim.height + this.chartDim.marginY * 2)
        )
        .selectAll('text')
        .attr('dy', '1.15em')
        .attr('dx', (d, i) => (i === 0 ? '0.2em' : i === 4 ? '-0.2em' : '0'))
        .style('text-anchor', (d, i) => (i === 0 ? 'start' : 'middle'));

      // bind data to group once
      svg.select('g.chart-area').selectAll('g.wrapper-data').data(this.dataMap).join('g').attr('class', 'wrapper-data');

      return x;
    },
    initYAxis() {
      // init y axis temp scale
      const y = d3
        .scaleLinear()
        .domain([0, 365])
        .range([`${this.chartDim.height - this.chartDim.marginY}`, this.chartDim.marginY]);

      const yAxis = this.chart
        .append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(0, 0)`)
        .transition()
        .duration(3000)
        .call(
          d3
            .axisLeft(y)
            .ticks(5)
            .tickSize(-this.chartDim.width + this.chartDim.marginX * 2)
        );

      yAxis
        .selectAll('text')
        .attr('dx', '-0.05em')
        .attr('dy', (d, i) => (i === 0 ? '-0.1em' : '0.75em'));

      return y;
    },
    drawAreaChart(id) {
      // clean up previous chart
      const chart = d3.select(`#chart-wrapper-area-${this.city}`).select('g.chart-area');
      chart.selectAll('g.area-group').remove();

      const area = chart.selectAll('g.wrapper-data').append('g').attr('class', `${id} area-group`);

      // draw the area chart + line border
      area.call((g) => {
        g.append('path')
          .attr('fill', '#DFF2F1')
          .attr('fill-opacity', 0)
          .transition()
          .duration(2000)
          .attr('fill-opacity', 0.98)
          .attr('stroke', 'none')
          .attr('d', (d) => {
            return d3
              .area()
              .x((d) => this.xScale(d.year))
              .y0(this.chartDim.height - this.chartDim.marginY)
              .y1((d) => this.yScale(+d[id]))(d[1]);
          });

        g.append('path')
          .attr('fill', 'none')
          .attr('stroke-width', 2)
          .attr('stroke', '#D8387B')
          .attr('d', (d) => {
            return d3
              .line()
              .curve(d3.curveMonotoneX)
              .x((d) => this.xScale(d.year))
              .y((d) => this.yScale(+d[id]))(d[1]);
          })
          .each(function () {
            const path = d3.select(this);
            const pathLength = path.node().getTotalLength();

            const transitionPath = d3.transition().duration(5000);

            path
              .attr('stroke-dashoffset', pathLength)
              .attr('stroke-dasharray', pathLength)
              .transition(transitionPath)
              .attr('stroke-dashoffset', 0);
          });
      });

      // add min-max values on chart
      const minmax = d3.extent(this.data, (d) => +d[id]);
      const extremePoints = this.data.filter((d) => minmax.includes(+d[id]));

      area
        .selectAll('text.minmax')
        .data(extremePoints)
        .enter()
        .append('text')
        .attr('class', 'minmax')
        .attr('text-anchor', 'middle')
        .text((d) => (+d[id] === +minmax[0] ? '-' : '+'))
        .attr('x', (d) => this.xScale(d.year))
        .attr('y', (d) => this.yScale(+d[id]))
        .attr('transform', 'translate(0, -20)')
        .raise();

      // add circles to do tooltip-area interaction
      area
        .selectAll('circle.points')
        .data((d) => d[1])
        .enter()
        .append('circle')
        .attr('class', 'points')
        .attr('fill', '#ADC2CC')
        .attr('fill-opacity', '0.05')
        .attr('r', 8)
        .attr('cx', (d) => this.xScale(d.year))
        .attr('cy', (d) => this.yScale(d[id]))
        .on('mouseover', (event, d) => {
          this.selectedItem = d;
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip-area.${this.city}`)
            .style('opacity', 0)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', x > this.chartDim.width / 2 ? `${x - 180}px` : `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip-area.${this.city}`).transition('outlol').duration(1500).style('opacity', 0);
        });

      // add annotation to chart
      const aveData = this.dataChange[id]
      if (aveData) {
        const annotationY = area
          .selectAll('circle.points')
          .filter((d) => d.year === '2021') // change this once Makkah data comes in
          .attr('cy');

        area
          .append('text')
          .attr('class', 'annotation')
          .attr('x', this.xScale('2021'))
          .attr('y', annotationY)
          .attr('dx', '1em')
          .text(`+${aveData}`);
      }        
    },
    drawMinMaxChart(min, max, id) {
      // clean up previous chart
      const chart = d3.select(`#chart-wrapper-area-${this.city}`).select('g.chart-area');
      chart.selectAll('g.area-group').remove();

      // draw the area chart + line border
      const area = chart.selectAll('g.wrapper-data').append('g').attr('class', `${id} area-group`);

      area.call((g) => {
        g.append('path')
          .attr('fill', '#DFF2F1')
          .attr('fill-opacity', 0)
          .transition()
          .duration(2000)
          .attr('fill-opacity', 0.98)
          .attr('stroke', 'none')
          .attr('d', (d) => {
            return d3
              .area()
              .x((d) => this.xScale(d.year))
              .y0((d) => this.yScale(+d[min]))
              .y1((d) => this.yScale(+d[max]))(d[1]);
          });

        g.append('path')
          .attr('fill', 'none')
          .attr('stroke-width', 2)
          .attr('stroke', '#D8387B')
          .attr('d', (d) => {
            return d3
              .line()
              .curve(d3.curveMonotoneX)
              .x((d) => this.xScale(d.year))
              .y((d) => this.yScale(+d[id]))(d[1]);
          })
          .each(function () {
            const path = d3.select(this);
            const pathLength = path.node().getTotalLength();

            const transitionPath = d3.transition().duration(5000);

            path
              .attr('stroke-dashoffset', pathLength)
              .attr('stroke-dasharray', pathLength)
              .transition(transitionPath)
              .attr('stroke-dashoffset', 0);
          });
      });

      // add circles to do tooltip-area interaction
      const circles = area
        .selectAll('circle.points')
        .data((d) => d[1])
        .enter()
        .append('circle')
        .attr('class', 'points')
        .attr('fill', '#ADC2CC')
        .attr('fill-opacity', '0.1')
        .attr('r', 8)
        .attr('cx', (d) => this.xScale(d.year))
        .attr('cy', (d) => this.yScale(d[id]));

      circles
        .on('mouseover', (event, d) => {
          this.selectedItem = d;
          let x = event.offsetX;
          let y = event.offsetY;
          d3.select(`.tooltip-area.${this.city}`)
            .style('opacity', 0)
            .transition('outlol')
            .duration(0)
            .style('opacity', 1)
            .style('top', `${y + 10}px`)
            .style('left', x > this.chartDim.width / 2.75 ? `${x - 160}px` : `${x}px`);
        })
        .on('mouseout', () => {
          d3.select(`.tooltip-area.${this.city}`).transition('outlol').duration(1500).style('opacity', 0);
        });

      // add min-max values on chart
      const minmax = d3.extent(this.data, (d) => +d[id]);
      const extremePoints = this.data.filter((d) => minmax.includes(+d[id]));

      area
        .selectAll('text.minmax')
        .data(extremePoints)
        .enter()
        .append('text')
        .attr('class', 'minmax')
        .attr('text-anchor', 'middle')
        .text((d) => (+d[id] === +minmax[0] ? '-' : '+'))
        .attr('x', (d) => this.xScale(d.year))
        .attr('y', (d) => this.yScale(+d[id]))
        .attr('transform', 'translate(0, -20)')
        .raise();

      // add annotation to chart
      const aveData = this.dataChange[id]
      if (aveData) {
        const annotationY = area
          .selectAll('circle.points')
          .filter((d) => d.year === '2021') // change this once Makkah data comes in
          .attr('cy');

        area
          .append('text')
          .attr('class', 'annotation')
          .attr('x', this.xScale('2021'))
          .attr('y', annotationY)
          .attr('dx', '1em')
          .text(`+${aveData}`);
      }
    },
    updateAreaChart(param) {
      switch (param.id) {
        case 'days_100f':
        case 'dangerous_days':
          this.updateYAxis([0, 365]);
          break;

        case 'annual_prep':
          this.updateYAxis([0, 600]);
          break;

        case 'winter_prep':
          this.updateYAxis([0, 250]);
          break;

        case 'summer_prep':
          this.updateYAxis([0, 150]);
          break;
      }
      this.updateYAxisTitle();
      this.drawAreaChart(param.id);
    },
    updateMinMaxChart(param, limitParam) {
      this.updateYAxisTitle();
      param.id.includes('temp') ? this.updateYAxis([0, 45]) : this.updateYAxis([0, 50]);
      this.drawMinMaxChart(limitParam.min, limitParam.max, param.id);
    },
    updateYAxis(domain) {
      this.yScale.domain(domain);
      const yAxis = d3.select(`#chart-wrapper-area-${this.city}`).selectAll('g.y-axis');

      yAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisLeft(this.yScale)
            .ticks(4)
            .tickSize(-this.chartDim.width + this.chartDim.marginX * 2)
        )
        .selectAll('text')
        .attr('dx', '-0.05em')
        .attr('dy', (d, i) => (i === 0 ? '-0.1em' : '0.75em'));
    },
    updateYAxisTitle() {
      d3.select(`#chart-wrapper-area-${this.city}`)
        .select('text.axis-label')
        .transition()
        .duration(250)
        .attr('opacity', 0)
        .transition()
        .duration(1000)
        .attr('opacity', 1)
        .text(this.axisLabel);                
    },
    toSentenceCase(str) {
      let param = str.replace('_', ' ');
      return param[0].toUpperCase() + param.slice(1);
    },
    responsivefy(svg) {
      const container = d3.select(svg.node().parentNode);
      const width = parseInt(svg.style('width'), 10);
      const height = parseInt(svg.style('height'), 10);
      const aspect = width / height;

      svg.attr('viewBox', `0 0 ${width} ${height}`).attr('preserveAspectRatio', 'xMinYMid').call(this.resize, aspect);

      d3.select(window).on(`resize.${container.attr('id')}`, () => {
        this.resize(svg, aspect);
      });
    },
    resize(svg, aspect) {
      const container = d3.select(svg.node().parentNode);
      const w = parseInt(container.style('width'));

      svg.attr('width', w);
      svg.attr('height', Math.round(w / aspect));
    },
  },
};
</script>

<style lang="scss">
.chart-wrapper-area {
  text,
  textPath {
    font-size: 12.5px;
    fill: #343768;
  }

  text.axis-label {
    font-size: 16px;
    text-transform: unset;
    font-weight: 500;
  }

  text.location-label {
    font-size: 18px;
    text-transform: unset;
    font-weight: 500;
    fill: #343768;
    text-transform: uppercase;
  }  

  g.tick line {
    stroke: #cdcabd;
    stroke-opacity: 0.5;
    /* stroke-dasharray: 4; */
  }

  path.domain {
    stroke: #cdcabd;
    stroke-opacity: 0.5;
  }

  circle.points:hover {
    cursor: pointer;
  }
}

g.area-group text.annotation {
  fill: #d8387b;
}

div.chart-wrapper-area {
  position: relative;

  div.tooltip-area {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 160px;
    max-width: 160px;
    height: auto;
    background: rgba(52, 55, 104, 0.7);
    padding: 8px;
    color: #f5f9fe;
    opacity: 0;
    pointer-events: none;
    z-index: 5;
  }
}

text.minmax {
  font-size: 15px;
  font-weight: 700;
}
</style>

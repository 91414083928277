<template>
  <header class="concept-landing pb-lg-12 dirchange">
    <v-container fluid class="px-5 px-md-9 px-lg-16">
      <v-row justify="start">
        <v-col cols="12" md="7" lg="7" xl="6" offset-md="2" class="text--aeon-navy">
          <h1 class="text-title mb-6 mb-md-8" v-html="$t('pageCompare.title')" />
          <h2 class="text-body font-weight-regular mb-8 mb-md-12">
            {{ $t('pageCompare.subtitle') }}
          </h2>

          <UiSocials class="mb-6" />

          <div class="d-flex flex-column flex-md-row">
            <div v-click-outside="handleClose">
              <v-btn outlined elevation="0" color="#343768" class="py-5 px-2" @click="embed = !embed">
                <v-icon color="#343768" style="font-size: 30px">mdi-share</v-icon>
                <span class="text-body-sm mb-0">{{ $t('ui.btn-share') }}</span>
              </v-btn>

              <transition name="block">
                <div class="pa-4 code-block" style="background: #f5f9fe" v-show="embed">
                  <p class="text-body-sm mb-1">
                    <span><v-icon color="#343768" style="font-size: 18px">mdi-xml</v-icon></span>
                    {{ $t('ui.btn-embed') }}
                  </p>
                  <form>
                    <input class="embed-code text-body-sm" type="text" :value="codeData" readonly />
                  </form>
                </div>
              </transition>
            </div>

            <div class="mt-4 mt-md-0 ml-md-6">
              <a href="/GCC Climate Change Data 1979 to 2021.xlsx" download="GCC Climate Change Data 1979 to 2021.xlsx">
                <v-btn outlined elevation="0" color="#343768" class="py-5 px-2">
                  <v-icon color="#343768" style="font-size: 30px">mdi-download</v-icon>
                  <span class="text-body-sm mb-0">{{ $t('ui.btn-download') }}</span>
                </v-btn>
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
import UiSocials from '@/components/ui/UiSocials';

export default {
  name: 'SectionLanding',
  components: {
    UiSocials,
  },
  data() {
    return {
      embed: false,
      codeData:
        "<iframe src='https://aeon.kontinentalist.studio/' title='GCC’s Changing Climate: A Quantified History of the Last 4 Decades' frameborder='0' scrolling='yes' style='width:100%;height:3719px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe>",
    };
  },
  methods: {
    handleClose() {
      this.embed ? (this.embed = false) : '';
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: unset !important;
}

.concept-landing {
  .v-btn {
    text-transform: unset !important;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 96px 4px 48px;
  }
}

input.embed-code {
  border: 1px solid #343768;
  padding: 4px;
  width: 100%;
  border-radius: 4px;
}

.code-block {
  width: 350px;
  margin: 0;
  padding: 12px 0;
  list-style-type: none;
  transform-origin: top;
  border-left: 1px solid #343768;
}

.block-enter {
  opacity: 0;
  max-height: 350px;
  transform: scaleY(0);
}

.block-enter-active {
  animation: slide-in 0.3s ease-out forwards;
}

.block-leave {
  opacity: 1;
  max-height: 0;
  overflow: hidden;
  transform: scaleY(0);
}

.block-leave-active {
  animation: slide-out 0.3s ease-out forwards;
}

@keyframes slide-in {
  from {
    max-height: 0;
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    max-height: 350px;
    transform: scaleY(100%);

    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    max-height: 350px;
    transform: scaleY(100%);
    opacity: 1;
  }
  to {
    max-height: 0;
    transform: scaleY(0);
    opacity: 0;
  }
}

.v-btn {
  width: 225px;
}
</style>

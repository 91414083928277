<template>
  <div>
    <!-- Legend images -->
    <div class="legend-group mb-6">
      <p class="text-body-sm text--aeon-navy font-weight-medium mb-2">{{ $t('vizui.legend') }}</p>

      <div v-show="!legendLine">
        <div class="legend-group--item mb-3" v-show="legend">
          <img class="image" src="@/assets/img/legend/area-legend1.svg" />
          <div class="label">
            <p
              v-for="(n, i) in $t('notes.average')"
              :key="i + 'legend-average'"
              class="ml-2 mb-1 text-body-xs text--aeon-navy"
            >
              {{ n }}
            </p>
          </div>
        </div>

        <div class="legend-group--item mb-3">
          <img class="image" src="@/assets/img/legend/area-legend2.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-2 text--aeon-navy">{{ $t('legend.minmax.high') }}</p>
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.minmax.low') }}</p>
          </div>
        </div>

        <div class="legend-group--item">
          <img class="image" src="@/assets/img/legend/area-legend3.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.line.change') }}</p>
          </div>
        </div>
      </div>

      <div v-show="legendLine">
        <div class="legend-group--item mb-3">
          <img class="image" src="@/assets/img/legend/line-legend1.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-2 text--aeon-navy">{{ $t('legend.line.increase') }}</p>
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.line.decrease') }}</p>          
          </div>
        </div> 

        <div class="legend-group--item mb-3">
          <img class="image" src="@/assets/img/legend/line-legend2.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-2 text--aeon-navy">{{ $t('legend.line.max-increase') }}</p>
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.line.max-decrease') }}</p>
          </div>
        </div>  

        <div class="legend-group--item mb-3">
          <img class="image" src="@/assets/img/legend/line-legend3.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.line.base-value') }}</p>
          </div>
        </div>   

        <div class="legend-group--item">
          <img class="image" src="@/assets/img/legend/line-legend4.svg" />
          <div class="label">
            <p class="ml-2 text-body-xs mb-0 text--aeon-navy">{{ $t('legend.line.temp-change') }}</p>
          </div>
        </div>   
                         
      </div>     
    </div>

    <!-- Notes -->
    <p class="text-body-sm font-weight-medium text--aeon-navy mb-2" id="notes-test">{{ $t('notes.header') }}</p>

    <div v-show="!legendLine">
      <div v-for="(n, i) in $t('notes.items-comparison-area')" :key="i + 'legend-climate'">
        <p class="text-body-xs text--aeon-orange mb-1">{{ n.title }}</p>
        <ol class="text-body-xs font-weight-light pl-4 mb-3">
          <li class="mb-2">
            {{ n.content1 }}
          </li>
          <li v-if="n.content2">
            {{ n.content2 }}
          </li>
        </ol>
      </div>
    </div>

    <div v-show="legendLine">
      <div v-for="(n, i) in $t('notes.items-comparison-line')" :key="i + 'legend-climate'">
        <p class="text-body-xs text--aeon-orange mb-1">{{ n.title }}</p>
        <p class="text-body-xs font-weight-light">
            {{ n.content1 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiLegend',
  props: {
    legend: {
      type: Boolean,
      default: true,
      required: true,
    },
    legendChange: {
      type: Boolean,
      default: true,
      required: true,
    },    
    legendLine: {
      type: Boolean,
      default: true,
      required: true,
    },       
  },
};
</script>

<style scoped lang="scss">
.legend-group {
  width: 100%;

  &--item {
    display: flex;
    align-items: center;

    img.image {
      width: 64px;
      @media #{map-get($display-breakpoints, 'xl-only')} {
        width: 84px;
      }
    }
  }
}
</style>

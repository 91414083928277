<template>
  <v-menu bottom left :close-on-content-click="false" offset-y nudge-bottom="8px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined elevation="0" color="#E27259" class="dropdown-button mr-4 mb-3" v-bind="attrs" v-on="on">
        <span class="text-body-sm">{{ menuLabel }}</span>
        <v-icon color="#E27259" style="font-size: 20px">{{ 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>

    <div class="dropdown-menu dirchange">
      <ul class="list">
        <v-checkbox
          v-for="item in itemList"
          :key="item.id"
          hide-details
          flat
          dense
          color="#A04031"
          class="pa-0"
          :ripple="false"
          :value="item.id"
          v-model="selectedCities"
        >
          <template v-slot:label>
            <span class="text-body-sm text--aeon-brown mb-0 font-weight-light">{{ $t(item.label) }}</span>
          </template>
        </v-checkbox>
      </ul>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'UiDropdownBtn',
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    menuLabel: {
      type: String,
      required: true,
    },
    resetState: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedCities: ['jeddah', 'makkah', 'riyadh'],
    };
  },
  watch: {
    selectedCities(newVal) {
      this.$emit('update-data', newVal.slice().sort());
      // this.$emit('update-data', newVal);
    },
    resetState(newVal) {
      if (newVal) {
        this.selectedCities = ['jeddah', 'makkah', 'riyadh'];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.dropdown-button {
  padding: 12px 16px !important;
  max-width: 140px;
  text-transform: unset !important;
}

.dropdown-menu {
  padding: 4px 16px 4px 16px;
  background: #ffffff;
  max-height: 800px;
  overflow-y: scroll;
  z-index: 10;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  hr {
    background-color: transparent;
    border-top: 1px dashed #ac584b;
  }

  .list {
    width: 100%;
    margin: 0;
    padding: 4px 0;
    list-style-type: none;
    transform-origin: top;

    li {
      margin-bottom: 8px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #df644b;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

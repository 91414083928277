<template>
  <div class="chart-wrapper-line">
    <div :id="`chart-wrapper-line-${city}`">
      <svg :id="`line-${city}`" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'VizLineChart',
  props: {
    city: {
      type: String,
      required: true,
    },
    parameter: {
      type: Object,
      default: () => {},
      required: true,
    },
    annotation: {
      type: Boolean,
      default: false,
      required: false,
    },
    trigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    chartDim() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return {
          width: 600,
          height: 300,
          marginY: 36,
          marginX: 60,
        };
      } else if (this.$vuetify.breakpoint.smOnly) {
        return {
          width: 760,
          height: 340,
          marginY: 36,
          marginX: 60,
        };
      } else {
        return {
          width: 580,
          height: 300,
          marginY: 30,
          marginX: 60,
        };
      }
    },
    locationLabel() {
      return this.$t('btn.location.' + this.city);
    },
    axisLabel() {
      return this.$t(this.parameter.axisLabel);
    }
  },
  data() {
    return {
      yearRange: [1950, 2027],
      data: null,
      dataChange: null,
    };
  },
  watch: {
    parameter(param) {
      this.updateLineChart(param)
    },
    trigger(newVal) {
      if (!newVal) {
        this.updateLineChart(this.parameter)
      }
    },
    '$i18n.locale': function() {
      const chart = d3.select(`#line-${this.city}`)
      // translate location label
      chart
        .selectAll('text.location-label')
        .text(this.locationLabel);

      // translate Y-axis label
      chart
        .selectAll('text.axis-label')
        .text(this.axisLabel);
    }    
  },
  async mounted() {
    const rawData = await d3.csv(`${process.env.BASE_URL}data/${this.city}.csv`);
    const rawDataChange = await d3.csv(`${process.env.BASE_URL}data/param-change.csv`);
    
    this.data = rawData.sort((a, b) => parseFloat(a.year) - parseFloat(b.year));
    this.dataMap = d3.group(this.data, (d) => d.location);

    this.dataChange = rawDataChange.find(d => d.location === this.city);

    // clean the chart before starting
    d3.select(`#line-${this.city}`).selectAll('g').remove();

    this.xScale = this.initChart();
    this.yScale = this.initYAxis();

    this.updateLineChart(this.parameter)
  },
  methods: {
    // drawing chart base functions
    initChart() {
      const innerWidth = this.chartDim.width - this.chartDim.marginX;
      const svg = d3
        .select(`#chart-wrapper-line-${this.city}`)
        .select(`#line-${this.city}`)
        .attr('width', this.chartDim.width)
        .attr('height', this.chartDim.height)
        .call(this.responsivefy);

      this.chart = svg
        .append('g')
        .attr('class', 'chart-line')
        .attr('transform', `translate(${this.chartDim.marginX}, 0)`);

      // draw grid lines
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M 0,${this.chartDim.marginY} L ${innerWidth},${this.chartDim.marginY} V ${this.chartDim.marginY},${this.chartDim.height} H ${innerWidth},0 Z`
        );

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M ${this.chartDim.marginX / 1.75},${this.chartDim.marginY} V ${this.chartDim.marginX / 1.75},${
            this.chartDim.height
          }`
        );

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#CDCABD')
        .attr(
          'd',
          `M 0,${this.chartDim.height - this.chartDim.marginY} L ${this.chartDim.marginX},${
            this.chartDim.height - this.chartDim.marginY
          } L  ${this.chartDim.marginX},${this.chartDim.height}`
        );

      svg
        .append('text')
        .attr('text-anchor', 'start')
        .attr('class', 'location-label')
        .attr('transform', `translate(0, 20)`)
        .text(this.locationLabel);

      svg
        .append('text')
        .attr('class', 'axis-label')
        .attr('opacity', 1)
        .attr('text-anchor', 'start')
        .attr(
          'transform',
          `translate(${this.chartDim.marginX / 3}, ${this.chartDim.height - this.chartDim.marginY - 2}) rotate(-90)`
        )
        .text(this.axisLabel);

      // draw the x-axis that doesn't change
      const x = d3
        .scaleLinear()
        .domain(this.yearRange)
        .range([0, this.chartDim.width - this.chartDim.marginX * 2]);

      this.chart
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${this.chartDim.height - this.chartDim.marginY})`)
        .call(
          d3
            .axisBottom(x)
            .tickFormat(d3.format('d'))
            .tickSize(-this.chartDim.height + this.chartDim.marginY)
            .tickSizeOuter(0)
        )
        .selectAll('text')
        .attr('dy', '1.15em')
        .attr('dx', '0.2em')
        .style('text-anchor', 'start');

      this.chart.select('g.x-axis').selectAll(".tick line").attr("transform", `translate(0, ${this.chartDim.marginY})`);

      // bind data to group once
      svg.select('g.chart-line').selectAll('g.wrapper-data').data(this.dataMap).join('g').attr('class', 'wrapper-data');

      return x;
    },
    initYAxis() {
      // init y axis temp scale
      const y = d3
        .scaleLinear()
        .domain([-3, 3])
        .range([`${this.chartDim.height - this.chartDim.marginY}`, this.chartDim.marginY]);

      const yAxis = this.chart
        .append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(0, 0)`)
        .transition()
        .duration(3000)
        .call(
          d3
            .axisLeft(y)
            .ticks(5)
            .tickSize(-this.chartDim.width + this.chartDim.marginX * 2)
        );

      yAxis
        .selectAll('text')
        .attr('dx', '-0.05em')
        .attr('dy', (d, i) => (i === 0 ? '-0.1em' : '0.75em'));

      d3.select('g.y-axis')
        .select("g.tick:nth-child(4)")
        .select('line')
        .attr('stroke-width', 1.5)

      return y;
    },
    drawLineChart(id) {
      // clean up previous chart
      const chart = d3.select(`#chart-wrapper-line-${this.city}`).select('g.chart-line');
      chart.selectAll('g.line-group').remove();

      const line = chart.selectAll('g.wrapper-data').append('g').attr('class', `${id} line-group`);

      // draw the line chart + line border
      line.call((g) => {
        g.append('path')
          .attr('fill', 'none')
          .attr('stroke-width', 2)
          .attr('stroke', '#64C4C5')
          .attr('d', (d) => {
            return d3
              .line()
              .curve(d3.curveMonotoneX)
              .x((d) => this.xScale(d.year))
              .y((d) => this.yScale(+d[id]))(d[1]);
          })
          .each(function () {
            const path = d3.select(this);
            const pathLength = path.node().getTotalLength();

            const transitionPath = d3.transition().duration(5000);

            path
              .attr('stroke-dashoffset', pathLength)
              .attr('stroke-dasharray', pathLength)
              .transition(transitionPath)
              .attr('stroke-dashoffset', 0);
          });
      });

      // add min-max values on chart
      const minmax = d3.extent(this.data, (d) => +d[id]);
      const extremePoints = this.data.filter((d) => minmax.includes(+d[id]));
      line
        .selectAll('text.minmax')
        .data(extremePoints)
        .enter()
        .append('text')
        .attr('class', 'minmax')
        .attr('text-anchor', 'middle')
        .text((d) => (+d[id] === +minmax[0] ? '-' : '+'))
        .attr('x', (d) => this.xScale(d.year))
        .attr('y', (d) => this.yScale(+d[id]))
        .attr('transform', 'translate(0, -20)')
        .raise();
        
      // add baseline text annotation on chart
      line
        .append('text')
        .attr('class', 'annotation-baseline')
        .attr('text-anchor', 'start')
        .text(this.dataChange[id] + '°C')
        .attr('x', this.xScale(2028))
        .attr('y', this.yScale(0))
        .raise();           

      // get endpoint data
      const endPoint = line.data()[0][1].find(d => d.year === "2021")
      line
        .append('text')
        .attr('class', 'annotation-change')
        .attr('text-anchor', 'start')
        .text('+' + this.dataChange['annual_temp'] + '°C')
        .attr('x', this.xScale(2028))
        .attr('y', this.yScale(endPoint['annual_temp_change']))
        .raise();            
       
    },
    updateLineChart(param) {
      this.updateYAxisTitle();
      this.drawLineChart(param.id);
    },
    updateYAxisTitle() {
      d3.select(`#chart-wrapper-line-${this.city}`)
        .select('text.axis-label')
        .transition()
        .duration(250)
        .attr('opacity', 0)
        .transition()
        .duration(1000)
        .attr('opacity', 1)
        .text(this.axisLabel);                
    },
    responsivefy(svg) {
      const container = d3.select(svg.node().parentNode);
      const width = parseInt(svg.style('width'), 10);
      const height = parseInt(svg.style('height'), 10);
      const aspect = width / height;

      svg.attr('viewBox', `0 0 ${width} ${height}`).attr('preserveAspectRatio', 'xMinYMid').call(this.resize, aspect);

      d3.select(window).on(`resize.${container.attr('id')}`, () => {
        this.resize(svg, aspect);
      });
    },
    resize(svg, aspect) {
      const container = d3.select(svg.node().parentNode);
      const w = parseInt(container.style('width'));

      svg.attr('width', w);
      svg.attr('height', Math.round(w / aspect));
    },
  },
};
</script>

<style lang="scss">
.chart-wrapper-line {
  text,
  textPath {
    font-size: 12px;
    fill: #343768;
  }

  text.axis-label {
    font-size: 13px;
    text-transform: unset;
    font-weight: 500;
  }

  text.location-label {
    font-size: 16px;
    text-transform: unset;
    font-weight: 500;
    fill: #343768;
    text-transform: uppercase;
  }  

  g.tick line {
    stroke: #cdcabd;
    stroke-opacity: 0.5;
  }

  path.domain {
    stroke: #cdcabd;
    stroke-opacity: 0.5;
  }

  text.minmax {
    font-size: 15px;
    font-weight: 700;
  }  
}

g.line-group text.annotation-baseline {
  fill: #848278;
}

g.line-group text.annotation-change {
  fill: #D8387B;
}

.chart-wrapper-line .y-axis g.tick:nth-child(5) line {
  stroke: #CDCABD;
  stroke-width: 1.8px;
  stroke-opacity: 1;
}
</style>

<template>
  <section class="page-charts--viz">
    <v-container fluid class="px-5 px-md-9 px-lg-16">
      <v-row justify="start">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" class="side-panel--sticky pr-8">
          <SidepanelCharts
            class="pt-6"
            :legend="legendAreaAve"
            :legendLine="isMultiplesView" 
            :legendChange="legendChange"
            v-intersect="{ handler: handleIntersectViz }" />
        </v-col>

        <v-col cols="12" md="10">
          <v-row justify="space-between" align="start" no-gutters class="mb-8 mb-md-12 mb-xl-16">
            <v-col cols="12" sm="6" lg="8">
              <p class="text-body-sm font-weight-medium mb-2 text--aeon-navy text-uppercase">
                {{ $t('vizui.variable') }}
              </p>

              <UiDropdownBtn
                :itemList1="variables.temperature"
                :menuLabel="$t('vizui.temp')"
                @update-data="handleParameter"
              />

              <UiDropdownBtn
                :itemList1="variables.anomaly"
                :menuLabel="$t('vizui.anomaly')"
                @update-data="handleSmallMultiples"
              />                       

              <UiDropdownBtn
                :itemList1="variables.heatindex"
                :menuLabel="$t('vizui.hi')"
                @update-data="handleParameter"
              />

              <UiDropdownBtn
                :itemList1="variables.hotdays"
                :menuLabel="$t('vizui.days')"
                @update-data="handleParameter"
              />

              <UiDropdownBtn
                :itemList1="variables.precipitation"
                :menuLabel="$t('vizui.precip')"
                @update-data="handleParameter"
              />     
            </v-col>

            <v-col cols="12" sm="6" lg="4" xl="3">
              <p
                class="
                  text-body-sm
                  mb-2
                  font-weight-medium
                  mr-3
                  text--aeon-navy
                  input-label
                  text-uppercase
                  mt-4 mt-sm-0
                "
              >
                {{ $t('vizui.location') }}
              </p>
              <UiDropdownBtnCheckbox
                :itemList="cities"
                :menuLabel="$t('vizui.loc')"
                :alignProp="true"
                :resetState="resetCities"
                @update-data="handleCities"
              />

              <v-btn outlined elevation="0" color="#E27259" class="mr-4 mb-3" @click="resetCities++">
                <v-icon color="#E27259" style="font-size: 20px">{{ 'mdi-refresh' }}</v-icon>
                <span class="text-body-sm ml-1">{{ $t('vizui.reset') }}</span>
              </v-btn>
            </v-col>

            <div @click="mobileLegend = !mobileLegend" class="mt-4" v-if="$vuetify.breakpoint.smAndDown">
              <span class="text-body-sm font-weight-medium text-uppercase text--aeon-navy">{{
                $t('vizui.legend')
              }}</span>
              <v-icon color="#E27259" style="font-size: 20px" class="ml-1">mdi-help-circle</v-icon>
            </div>
          </v-row>

          <v-row no-gutters class="mb-8" :class="$vuetify.breakpoint.smAndDown ? 'sticky-bar' : ''">
            <v-col cols="12" lg="8">
              <p
                v-if="selectedCities.length === 0"
                class="text-body-sm font-weight-medium text--aeon-navy text-uppercase"
              >
                {{ $t('vizui.fallback') }}
              </p>

              <p v-else class="text-body-sm font-weight-medium text--aeon-navy text-uppercase">
                {{ $t('statusbar.comparing') }}:
                <span class="text--aeon-orange" v-if="selectedParameter.labelStatus">{{ $t(selectedParameter.labelStatus) }}</span>
                <span class="text--aeon-orange" v-else-if="selectedParameter">{{ $t(selectedParameter.label) }}</span>

                {{ $t('statusbar.between') }}
                <span v-if="selectedCities.length === 12" class="text--aeon-orange">{{ $t('statusbar.all') }}</span>
                <span v-else class="text--aeon-orange">{{ handleSanitiseCityLabels(selectedCities).join(', ') }}</span>
              </p>
            </v-col>
          </v-row>

          <v-row justify="start" align="start" v-if="this.paramList.length > 23 && !this.isMultiplesView">
            <v-col cols="12" md="11" xl="10" class="mb-8 pr-xl-16" v-for="n in selectedCities" :key="n">
              <VizAreaChart :range="range" :city="n" :parameter="selectedParameter" :trigger="isIntersecting" />
            </v-col>
          </v-row>

          <v-row justify="start" align="start" v-else-if="this.paramList.length > 23 && this.isMultiplesView">
            <v-col cols="12" md="6" xl="4" class="mb-8" v-for="n in selectedCities" :key="n + '-hello'">
              <VizLineChart :city="n" :parameter="selectedParameter" :trigger="isIntersecting" />
            </v-col>
          </v-row>          
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      hide-overlay
      :key="'mobile-legend-chart'"
      :elevation="0"
      v-model="mobileLegend"
      transition="dialog-bottom-transition"
      max-width="400px"
    >
      <div class="pa-3" style="background: rgba(250, 250, 250, 0.95)">
        <SidepanelCharts :legend="legendAreaAve" :legendChange="legendChange" :legendLine="isMultiplesView" />
      </div>
    </v-dialog>
  </section>
</template>

<script>
import UiDropdownBtn from '@/components/ui/UiDropdownBtn';
import UiDropdownBtnCheckbox from '@/components/ui/UiDropdownBtnCheckbox';
import VizAreaChart from '@/components/viz/VizAreaChart';
import VizLineChart from '@/components/viz/VizLineChart';

import SidepanelCharts from '@/components/page-chart/SidepanelCharts';

import { csv, extent } from 'd3';

export default {
  name: 'SectionComparisonCharts',
  components: {
    UiDropdownBtn,
    UiDropdownBtnCheckbox,
    SidepanelCharts,
    VizAreaChart,
    VizLineChart
  },
  computed: {
    range() {
      let param = this.selectedParameter.id;
      const isArea = ['days', 'prep'].some((d) => param.includes(d));

      if (!isArea && this.paramList.length > 1) {
        let season = param.split('_')[0],
          variable = param.split('_')[1],
          paramMinMax = { min: '', max: '', limit: [] };

        if (season !== 'annual') {
          paramMinMax.min = this.paramList.find((d) => d.id === `${season}_min_${variable}`).id;
          paramMinMax.max = this.paramList.find((d) => d.id === `${season}_max_${variable}`).id;
          paramMinMax.limit[0] = this.paramList.find((d) => d.id === `${season}_min_${variable}`).limit[0];
          paramMinMax.limit[1] = this.paramList.find((d) => d.id === `${season}_max_${variable}`).limit[1];
        } else {
          paramMinMax.min = this.paramList.find((d) => d.id === `min_${variable}`).id;
          paramMinMax.max = this.paramList.find((d) => d.id === `max_${variable}`).id;
          paramMinMax.limit[0] = this.paramList.find((d) => d.id === `min_${variable}`).limit[0];
          paramMinMax.limit[1] = this.paramList.find((d) => d.id === `max_${variable}`).limit[1];
        }

        return paramMinMax;
      }
      return null;
    },
    legendAreaAve() {
      let param = this.selectedParameter.id;
      const isArea = ['days', 'prep'].some((d) => param.includes(d));

      if (isArea) {
        return false;
      }
      return true;
    },
    legendChange() {
      let param = this.selectedParameter.id;
      const isTemp = param.includes('_temp');
      if (isTemp) {
        return true;
      }
      return false;
    },    
  },
  data() {
    return {
      variables: {
        temperature: [
          { label: 'btn.temp.annual', id: 'annual_temp', axisLabel: 'areaChartAxis.annualtemp' },
          { label: 'btn.temp.summer', id: 'summer_temp', axisLabel: 'areaChartAxis.summertemp' },
          { label: 'btn.temp.winter', id: 'winter_temp', axisLabel: 'areaChartAxis.wintertemp' },
        ],
        heatindex: [
          { label: 'btn.hi.annual', id: 'annual_hi', axisLabel: 'areaChartAxis.annualhi' },
          { label: 'btn.hi.summer', id: 'summer_hi', axisLabel: 'areaChartAxis.summerhi' },
          { label: 'btn.hi.winter', id: 'winter_hi', axisLabel: 'areaChartAxis.winterhi' },
        ],
        hotdays: [
          { label: 'btn.days.dangerous_days', id: 'dangerous_days', axisLabel: 'areaChartAxis.dangerdays' },
          { label: 'btn.days.100f_days', id: 'days_100f', axisLabel: 'areaChartAxis.100fdays' },
        ],
        precipitation: [
          { label: 'btn.precip.annual', id: 'annual_prep', axisLabel: 'areaChartAxis.annualprecip' },
          { label: 'btn.precip.summer', id: 'summer_prep', axisLabel: 'areaChartAxis.summerprecip' },
          { label: 'btn.precip.winter', id: 'winter_prep', axisLabel: 'areaChartAxis.winterprecip' },
        ],
        anomaly: [
          { label: 'btn.anomaly.temp-annual', labelStatus: 'statusbar.anomaly.temp-annual', id: 'annual_temp_change', axisLabel: 'lineChartAxis.annualtemp' },
          // { label: 'btn.temp.summer', id: 'summer_temp_change', axisLabel: 'areaChartAxis.summertemp' },
          // { label: 'btn.temp.winter', id: 'winter_temp_change', axisLabel: 'areaChartAxis.wintertemp' },
          // { label: 'btn.hi.annual', id: 'annual_hi_change', axisLabel: 'areaChartAxis.annualhi' },
          // { label: 'btn.hi.summer', id: 'summer_hi_change', axisLabel: 'areaChartAxis.summerhi' },
          // { label: 'btn.hi.winter', id: 'winter_hi_change', axisLabel: 'areaChartAxis.winterhi' },          
        ],        
      },
      cities: [
        { label: 'btn.location.abu-dhabi', id: 'abu-dhabi' },
        { label: 'btn.location.dammam', id: 'dammam' },
        { label: 'btn.location.doha', id: 'doha' },
        { label: 'btn.location.dubai', id: 'dubai' },
        { label: 'btn.location.jeddah', id: 'jeddah' },
        { label: 'btn.location.kuwait-city', id: 'kuwait-city' },
        { label: 'btn.location.makkah', id: 'makkah' },
        { label: 'btn.location.manama', id: 'manama' },
        { label: 'btn.location.muscat', id: 'muscat' },
        { label: 'btn.location.riyadh', id: 'riyadh' },
        { label: 'btn.location.gcc', id: 'gcc' },
        { label: 'btn.location.ksa', id: 'ksa' },
      ],
      // default selection variables
      selectedCities: ['jeddah', 'makkah', 'riyadh'],
      selectedParameter: {
        label: 'btn.temp.annual',
        id: 'annual_temp',
        axisLabel: 'areaChartAxis.annualtemp',
      },
      paramList: [],
      resetCities: 0,
      mobileLegend: false,
      isIntersecting: false,
      isMultiplesView: false, 
    };
  },
  async mounted() {
    const rawData = await csv(`${process.env.BASE_URL}data/allcities.csv`)
    const list = rawData.columns.slice(2);

    list.forEach((item) => {
      const param = { id: '', limit: null };
      param.id = item;
      param.limit = extent(rawData, (d) => +d[item]);
      this.paramList.push(param);
    });
  },
  methods: {
    handleParameter(event) {
      this.isMultiplesView = false;
      this.selectedParameter = event;
    },
    handleSmallMultiples(event) {
      this.isMultiplesView = true;
      this.selectedParameter = event;
    },    
    handleCities(event) {
      this.selectedCities = event;
    },
    handleIntersectViz(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    handleSanitiseCityLabels(cities) {
      const sanitisedCityLabels = [];
      if (cities) {
        // filter cities by selected
        const selected = this.cities.filter((city) => cities.includes(city.id));
        // extract city label
        selected.forEach((d) => {
          sanitisedCityLabels.push(this.$t(d.label));
        });
      }
      return sanitisedCityLabels;
    },
  },
};
</script>

<style scoped lang="scss">
.page-charts--viz {
  background: #f5f9fe;

  .v-btn {
    text-transform: unset !important;
  }

  .side-panel--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 16px;
    height: calc(100vh - 32px);
    overflow: hidden;
    border-right: 1px solid #343768;
  }

  .sticky-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 68px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: #f5f9fe;
    z-index: 2;
    height: auto;
    overflow: hidden;
    justify-content: space-between;
  }
}
</style>

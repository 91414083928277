<template>
  <div class="page-compare">
    <div class="page-compare--landing pb-16">
      <UiNavbar />
      <SectionLanding />
    </div>

    <SectionComparisonChart />

    <SectionMethods />

    <SectionFooter />
  </div>
</template>

<script>
import SectionLanding from '@/components/page-chart/SectionLanding';
import SectionComparisonChart from '@/components/page-chart/SectionComparisonChart';
import SectionMethods from '@/components/SectionMethods';
import SectionFooter from '@/components/SectionFooter';
import UiNavbar from '@/components/ui/UiNavbar.vue';

export default {
  name: 'AeonComparisonCharts',
  components: {
    SectionLanding,
    SectionComparisonChart,
    SectionMethods,
    SectionFooter,
    UiNavbar,
  },
};
</script>

<style scoped lang="scss">
.page-compare {
  position: relative;

  &--landing {
    background: linear-gradient(180deg, #aad4e7 0%, rgba(170, 212, 231, 0.7) 0.01%, #f6faff 100%);
  }
}
</style>
